import '../../../scss/critical.scss';
import '../../../scss/index.scss';

const $ = require('jquery');
global.$ = global.jQuery = $;

$(document).ready(function () {
    import(
        /* webpackMode: "lazy" */
        '../qg-main'
    ).then(function () {
        const divs = document.querySelectorAll('.slider-wrapper > div');

        divs.forEach((div) => {
            const id = div.dataset.id;
            const beforeImage = document.querySelector(`.image-comparison[data-id="${id}"] .before-image`);
            const sliderLine = document.querySelector(`.image-comparison[data-id="${id}"] .slider-line`);
            //slider position for upscale requires special handling
            if (id === 'ai-upscale') {
                beforeImage.style.width = '65%';
                sliderLine.style.left = '65%';
            }
            if (id === 'ai-upscale-mobile') {
                beforeImage.style.width = '75%';
                sliderLine.style.left = '75%';
            }

            div.addEventListener('mousemove', (e) => {
                let sliderValue = e.pageX - div.getBoundingClientRect().left - window.scrollX + 'px';
                if (beforeImage) beforeImage.style.width = sliderValue;
                if (sliderLine) sliderLine.style.left = sliderValue;
            });
            div.addEventListener('mouseleave', (e) => {
                //slider position for upscale requires special handling
                if (id === 'ai-upscale') {
                    beforeImage.style.width = '65%';
                    sliderLine.style.left = '65%';
                } else {
                    if (beforeImage) beforeImage.style.width = '50%';
                    if (sliderLine) sliderLine.style.left = '50%';
                }
            });

            // MOBILE SCREEN
            div.addEventListener('touchmove', (e) => {
                let sliderValue = e.touches[0].pageX - div.getBoundingClientRect().left - window.scrollX + 'px';
                let sliderPosition = e.touches[0].pageX - div.getBoundingClientRect().left - window.scrollX;

                if (sliderPosition < div.getBoundingClientRect().width) {
                    if (beforeImage) beforeImage.style.width = sliderValue;
                    if (sliderLine) sliderLine.style.left = sliderValue;
                }
            });
            div.addEventListener('touchend', (e) => {
                //slider position for upscale requires special handling
                if (id === 'ai-upscale-mobile') {
                    beforeImage.style.width = '75%';
                    sliderLine.style.left = '75%';
                } else {
                    if (beforeImage) beforeImage.style.width = '50%';
                    if (sliderLine) sliderLine.style.left = '50%';
                }
            });
        });
    });
});
